.icon {
  margin-left: 5px;
  margin-right: 15px;
  padding: 0;
  display: inline-block;
  text-align: center;
  //transition: all .3s ease-in-out;

  &.logo {
    max-width: 80px;
  }

  i {
    margin: 0;
  }
}

img {
  &.icon {
    max-width: 230px;
  }
}

.icon-sm {
  @include icon-size($font-size-small);
}
.icon-md {
  @include icon-size($font-size-m);
}
.icon-lg {
  @include icon-size($font-size-l);
}
.icon-xl {
  @include icon-size($font-size-xl);
}
.icon-xxl {
  @include icon-size($font-size-2xl);
}

.list-icon {
  list-style-type: none;

  li {
    > * {
      display: table-cell;
    }

    i {
      padding-right: 15px;
    }
  }
}

.icon-block {
  padding: $padding-default * 0.25;
  margin-bottom: $padding-default * 0.25;

  p {
    margin-top: 10px;
  }
}

.icon-box,
.icon-shape {
  @include center-flex();
}

.icon-box {
  padding: 1rem;
}

.icon-shape {
  position: relative;
  flex-direction: column;

  .icon {
    margin: 0;
    position: absolute;
    @include center(xy);
  }
}
