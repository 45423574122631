/* ==========================================================================
    [9. CUSTOM]
========================================================================== */

$big-mockup-width: 920px;

p {
  margin: 1rem 0;
}

.logo {
  max-width: 180px;
}

.swiper-container {
  .swiper-pagination-top {
    bottom: auto;
    top: 0;
  }
}

hr[class*="bg"] {
  border-top: 0;
  height: 1px;
}

.off-left-background {
  background-position: -60px 20px;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s ease;

  &:hover {
    background-position: -60px 0px;
    transform: translate3d(0, -10px, 0);
  }
}

.nav-process {
  min-height: 60px;
}

.labelerror {
  color: theme-color("danger");
  font-size: $font-size-small;
}

.labelSuccess {
  color: green;
  font-size: $font-size-small;
  text-align: center;
}
.attachedPDF {
  // border: 1px green dotted;
  float: left;
  display: block;
  padding-left: 5em;
  width: 400pt;
}
.redbox {
  border: 1px red solid;
}
.teamBoxProfile {
  width: 90%;
  display: block;

  img {
    max-width: 150px;
    border-radius: 1em;
    padding: 10px;
  }
}
.pushLeft {
  float: left;
  display: block;
}
.teamProfileDesc {
  float: left;
  display: block;
  padding-left: 10px;
  text-align: justify;
}
.teamProfileSocial {
  padding-left: 10px;
}
.lessMarginTop {
  padding-top: 5px !important;
  padding-left: 0px;
}
.padding-left-btn-touch {
  margin-left: 30px;
}
.sendToBack {
  z-index: 0;
}
.marginForMobile {
  padding-top: 10em;
}
.marginForMobileServices {
  padding-bottom: 3em;
}

.scrum {
  margin-left: -10px;
}

.nav-follow {
  a {
    margin-right: 10px;
  }
}

.recaptcha-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.main-home-page {
  position: relative;
}
