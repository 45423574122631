/* 2.2 Border ======================================================= */
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (top: t, right: r, bottom: b, left: l) {
      .border#{$infix}-#{$abbrev} {
        border-#{$prop}: 1px solid $border-color;
      }
    }
  }
}

// Shadows
@each $i, $color in $theme-colors {
  .shadow-#{$i} {
    box-shadow: 0 1px 10px rgba($color, 0.4) !important;
  }
}

.shadow {
  box-shadow: 0 1px 9px 1px rgba(0, 0, 0, 0.15);
}

.drop-shadow {
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25));
}

.shadow-box {
  transition: box-shadow 0.3s linear;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(0, 0, 0, 0.03);
}

.shadow-hover {
  transition: box-shadow 0.3s ease-in-out;
  @include hover-shadow(#000, 3px, 15px, 0.085);
}

.border-input {
  border: 1px solid $input-border-color;
}

.rounded-circle img {
  border-radius: 50%;
}

.bw-2 {
  border-width: 2px;
}

// GILNEI CUSTOM
.imagecontainer {
  margin: 50px auto;
  display: block;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 28px;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Safari and Chrome */
  position: relative;

  /* non-essential styling */
  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.05);
}
.imagecontainer img {
  transform: rotate(-45deg);
  -ms-transform: rotate(-45deg); /* IE 9 */
  -webkit-transform: rotate(-45deg); /* Safari and Chrome */
  position: absolute;
  top: -100px;
  left: -100px;
}

.hivebkp1 {
  width: 0;
  border-bottom: 30px solid #6c6;
  border-left: 52px solid transparent;
  border-right: 52px solid transparent;

  width: 104px;
  height: 60px;
  background-color: #6c6;

  width: 0;
  border-top: 30px solid #6c6;
  border-left: 52px solid transparent;
  border-right: 52px solid transparent;
}
