.gradient {
  background-color: transparent;

  @each $name, $gradient-colors in $gradients-map {
    @each $j, $gradient in $gradient-colors {
      $base-color: map-get($theme-colors, $name);
      $end-color: map-get($gradient-colors, $j);

      &.gradient-#{$name}-#{$j} {
        background-image: linear-gradient(
          45deg,
          $base-color 0,
          $end-color 100%
        );
      }
    }
  }

  &.gradient-primary-auxiliary {
    background-image: linear-gradient(
      45deg,
      $auxiliary 0,
      theme-color("primary") 50%,
      theme-color("secondary") 110%
    );
  }

  // Fix when applying .gradient to a .card
  &.card .card-header {
    background-color: transparent;
  }
}
